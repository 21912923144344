import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useAuth from "../hooks/useAuth";
import * as referralApi from "../api/referrals";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import PersonalDetailsForm from "../components/ReferralBuilder/PersonalDetailsForm";
import ServiceDetailsForm from "../components/ReferralBuilder/ServiceDetailsForm";
import DelegateInfoForm from "../components/ReferralBuilder/DelegateInfoForm";
import { hideCompensationQuestions, isCompensationRehabilitationAssessmentServiceType, isCompensationServiceType, isEarlyInterventionType, isExamineeServiceType } from "../constants/referralConstants";
import dayjs from "dayjs";
import Loading from "../components/Loading/Loading";

export default function ReferralBuilderPage() {
    const { user, setSuccess, loading, call, contextMap, setContextMap, clearContext } = useAuth();
    const { id } = useParams();
    const [ referralInfo, setReferralInfo ] = useState();
    const [ activeStep, setActiveStep ] = useState(0);
    const [ initialDelegateInfo, setInitialDelegateInfo ] = useState();
    const [ consent, setConsent ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        call(referralApi.infoByPrice, { id }, (response) => {
            setReferralInfo(response);
            setContextMap(new Map(contextMap.set('priceId', id)));
        });
        call(referralApi.delegateByUser, { userId: user.email }, (response) => {
            if (!response) {
                response = {
                    agencyReferralId: "",
                    delegateGivenName: "",
                    delegateSurname: "",
                    delegatePosition: "",
                    delegateSalutation: "",
                    rcmAgsNumber: "",
                    rcmEmail: "",
                    rcmFax: "",
                    rcmPhone: "",
                    s36Authority: "",
                    employerAgency: "",
                };
            }
            setInitialDelegateInfo({
                agencyReferralId: response.agencyReferralId || "",
                delegateGivenName: response.delegateGivenName || "",
                delegateSurname: response.delegateSurname || "",
                delegatePosition: response.delegatePosition || "",
                delegateSalutation: response.delegateSalutation || "",
                rcmAgsNumber: response.rcmAgsNumber || "",
                rcmEmail: response.rcmEmail || "",
                rcmFax: response.rcmFax || "",
                rcmPhone: response.rcmPhone || "",
                s36Authority: response.s36Authority || "",
                employerAgency: response.employerAgency || "",
            });
        });
    },
    // eslint-disable-next-line
    [ id ]);

    useEffect(() => {
        window.scrollTo(0, 0)
    },
    // eslint-disable-next-line
    [ activeStep ]);

    function clearReferralContext() {
        clearContext('personalDetails');
        clearContext('serviceDetails');
        clearContext('delegateInfo');
    }
    
    function handleCancel() {
        clearReferralContext();
        navigate("/seller-catalogue");
    }

    function setPersonalDetails(details) {
        setContextMap(new Map(contextMap.set('personalDetails', details)));
    }

    function personalDetails() {
        return contextMap.get('personalDetails');
    }

    function setServiceDetails(details) {
        setContextMap(new Map(contextMap.set('serviceDetails', details)));
    }

    function serviceDetails() {
        return contextMap.get('serviceDetails');
    }

    function setDelegateInfo(info) {
        setContextMap(new Map(contextMap.set('delegateInfo', info)));
    }

    function delegateInfo() {
        return contextMap.get('delegateInfo');
    }

    function handlePersonalDetailsSubmit(form) {
        setPersonalDetails(form);
        setActiveStep(1);
    }
    
    function handleServiceDetailsSubmit(form) {
        setServiceDetails(form);
        setActiveStep(2);
      }
    
    function handleServiceDetailsBack(form) {
        const newForm = {
            ...form,
            dateOfInjury: form.dateOfInjury && dayjs(form.dateOfInjury).isValid() ? form.dateOfInjury : "",
            rehabAssessmentDate: form.rehabAssessmentDate && dayjs(form.rehabAssessmentDate).isValid() ? form.rehabAssessmentDate : "",
        };
        setServiceDetails(newForm);
        setActiveStep(0);
    }
    
    function handleDelegateInfoSubmit(form) {
        setDelegateInfo(form);

        const referralDetails = Object.assign(
            {},
            personalDetails(),
            serviceDetails(),
            form,
        );

        const { serviceTypeId: serviceId } = referralInfo;

        if (isEarlyInterventionType(serviceId) ||
            isExamineeServiceType(serviceId) ||
            isCompensationServiceType(serviceId) ||
            hideCompensationQuestions(serviceId) ||
            !referralDetails.referralType
        ) {
            referralDetails.referralType = 'Non-Compensation';
        }

        if (!isExamineeServiceType(serviceId) &&
            !isCompensationRehabilitationAssessmentServiceType(serviceId)
        ) {
            referralDetails.managerEmailRequired = true;
        }

        const referralToPost = {
            employeeSalutation: referralDetails.employeeSalutation,
            employeeGivenName: referralDetails.employeeGivenName,
            employeeSurname: referralDetails.employeeSurname,
            restrictedPerson: referralDetails.restrictedPerson,
            employeeGender: referralDetails.employeeGender,
            employeePhone: referralDetails.employeePhone,
            employeeEmail: referralDetails.employeeEmail,
            employeeHomeAddress: referralDetails.employeeHomeAddress,
            employeeHomeAddressSuburb: referralDetails.employeeHomeAddressSuburb,
            employeeHomeAddressState: referralDetails.employeeHomeAddressState,
            employeeHomeAddressPostcode: referralDetails.employeeHomeAddressPostcode,
            requiresInterpreter: referralDetails.requiresInterpreter,
            employeeInterpreterLanguage: referralDetails.employeeInterpreterLanguage,
            employeeAgsContractorNumber: referralDetails.employeeAgsContractorNumber,
            employeeCurrentJobStatus: referralDetails.employeeCurrentJobStatus,
            employeeCurrentEmploymentType: referralDetails.employeeCurrentEmploymentType,
            employeeJobTitle: referralDetails.employeeJobTitle,
            employeeDateOfBirth: referralDetails.employeeDateOfBirth ? dayjs(referralDetails.employeeDateOfBirth).format('YYYY-MM-DD') : undefined,
            employeeSectionAndBranch: referralDetails.employeeSectionAndBranch,
            employeeLocationInPremises: referralDetails.employeeLocationInPremises,
            employeeWorkAddress: referralDetails.employeeWorkAddress,
            employeeWorkAddressSuburb: referralDetails.employeeWorkAddressSuburb,
            employeeWorkAddressState: referralDetails.employeeWorkAddressState,
            employeeWorkAddressPostcode: referralDetails.employeeWorkAddressPostcode,
            employeeWorkPhone: referralDetails.employeeWorkPhone,
            callCentreEmployee: referralDetails.callCentreEmployee,
            managerAgsNumber: referralDetails.managerAgsNumber,
            managerSalutation: referralDetails.managerSalutation,
            managerGivenName: referralDetails.managerGivenName,
            managerSurname: referralDetails.managerSurname,
            managerWorkAddress: referralDetails.managerWorkAddress,
            managerWorkAddressSuburb: referralDetails.managerWorkAddressSuburb,
            managerWorkAddressState: referralDetails.managerWorkAddressState,
            managerWorkAddressPostcode: referralDetails.managerWorkAddressPostcode,
            managerEmail : referralDetails.managerEmail,
            managerWorkPhone: referralDetails.managerWorkPhone,
            treatingDoctorName: referralDetails.treatingDoctorName,
            treatingDoctorPhone: referralDetails.treatingDoctorPhoneNumber,
            treatingDoctorFax: referralDetails.treatingDoctorFaxNumber,
            treatingDoctorAddress: referralDetails.treatingDoctorAddress,
            treatingDoctorAddressSuburb: referralDetails.treatingDoctorAddressSuburb,
            treatingDoctorAddressState: referralDetails.treatingDoctorAddressState,
            treatingDoctorAddressPostcode: referralDetails.treatingDoctorAddressPostcode,
            managerEmailRequired: referralDetails.managerEmailRequired,
            referralType: referralDetails.referralType,
            comcareClaimNumber: referralDetails.comcareClaimNumber, 
            liabilityDetermined: referralDetails.liabilityDetermined, 
            dateOfInjury: referralDetails.dateOfInjury ? dayjs(referralDetails.dateOfInjury).format('YYYY-MM-DD') : undefined,
            injuryDetails: referralDetails.injuryDetails,
            employeeCurrentlyAtWork: referralDetails.employeeCurrentlyAtWork,
            postInjuryHours: referralDetails.postInjuryHours,
            postInjuryDuties: referralDetails.postInjuryDuties,
            medicalSpecialistName: referralDetails.medicalSpecialistName,
            legallyQualifiedMedicalPractitioner: referralDetails.legallyQualifiedMedicalPractitioner,
            suitablyQualifiedPerson: referralDetails.suitablyQualifiedPerson,
            panel: referralDetails.panel,
            rehabAssessmentVenueAddress: referralDetails.rehabAssessmentVenueAddress,
            rehabAssessmentVenueAddressSuburb: referralDetails.rehabAssessmentVenueAddressSuburb,
            rehabAssessmentVenueAddressState: referralDetails.rehabAssessmentVenueAddressState,
            rehabAssessmentVenueAddressPostcode: referralDetails.rehabAssessmentVenueAddressPostcode,
            rehabAssessmentVenuePhone: referralDetails.rehabAssessmentVenuePhone,
            rehabAssessmentDate: referralDetails.rehabAssessmentDate ? dayjs(referralDetails.rehabAssessmentDate).format('YYYY-MM-DD') : undefined,
            rehabAssessmentTime: referralDetails.rehabAssessmentTime,
            rehabProviderToContact: referralDetails.rehabProviderToContact,
            rehabAuthorisedAssessmentCost: referralDetails.rehabAuthorisedAssessmentCost,
            assessor1Name: referralDetails.assessor1Name,
            assessor2Name: referralDetails.assessor2Name,
            assessor3Name: referralDetails.assessor3Name,
            assessorPhone: referralDetails.assessorPhone,
            agencyReferralId: referralDetails.agencyReferralId,
            delegateGivenName: referralDetails.delegateGivenName,
            delegateSurname: referralDetails.delegateSurname,
            delegatePosition: referralDetails.delegatePosition,
            delegateSalutation: referralDetails.delegateSalutation,
            rcmAgsNumber: referralDetails.rcmAgsNumber,
            rcmEmail: referralDetails.rcmEmail,
            rcmFax: referralDetails.rcmFax, 
            rcmPhone: referralDetails.rcmPhone,
            s36Authority: referralDetails.s36Authority,
            s36Reasons: referralDetails.s36Reasons,
            employerAgency: referralDetails.employerAgency,
            caseNotes: referralDetails.caseNotes,
            originalReferralId: referralDetails.originalReferralId,
        };

        const payload = {
            priceId: parseInt(id, 10),
            serviceTypeId: serviceId,
            detail: referralToPost
        };

        setSuccess(undefined);
        call(referralApi.create, { payload }, (response) => {
            setSuccess('Referral created.');
            clearReferralContext();
            navigate("/seller-catalogue");
        });

    }
    
    function handleDelegateInfoBack(form) {
        setDelegateInfo(form);
        setActiveStep(1);
    }

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <Box
            sx={{
                margin: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h1" sx={{ mb: 2 }}>Send Referral</Typography>
            <Divider  sx={{ mb: 2 }} />
            {referralInfo &&
                <Grid container spacing={1}  sx={{ mb: 2 }} >
                    <Grid item xs={12} sm={3}>
                        <Typography component="p" variant="body">Supplier</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography component="p" variant="body2">{referralInfo.providerName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography component="p" variant="body">Service</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography component="p" variant="body2">{`${referralInfo.serviceTypeName} (${referralInfo.categoryName})`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography component="p" variant="body">Region</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography component="p" variant="body2">{`${referralInfo.regionState} ${referralInfo.regionName}`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography component="p" variant="body">Price</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        {referralInfo.feeType
                         && referralInfo.feeType === 'Negotiated'
                         &&
                            <Typography component="p" variant="body2">Agencies are to discuss with the provider to reach an agreement on any additional costs and suitable timeframes</Typography>
                        }
                        {referralInfo.feeType
                         && referralInfo.feeType !== 'Negotiated'
                         &&
                            <Typography component="p" variant="body2">{`$${referralInfo.price} inc GST`}</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography component="p" variant="body">Agency</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Typography component="p" variant="body2">{user.organisation}</Typography>
                    </Grid>
                </Grid>
            }
            <FormControl error={!consent} variant="standard">
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            onChange={(e) => setConsent(e.target.checked)}
                            checked={consent}
                        />
                    }
                    label={
                        <Typography>
                            I confirm that the employee has expressly consented for Comcare to collect, use and disclose their personal and sensitive information to manage ORAMS
                        </Typography>
                    }
                />
                {!consent &&
                    <FormHelperText>{"Confirm the employee's consent"}</FormHelperText>
                }   
            </FormControl>
            {consent &&
                <Stepper activeStep={activeStep} orientation="vertical"  sx={{ mt: 2 }} >
                    <Step>
                        <StepLabel>
                            Personal Details
                        </StepLabel>
                        <StepContent>
                            <PersonalDetailsForm referralInfo={referralInfo} handleOnCancel={handleCancel} handleOnSubmit={handlePersonalDetailsSubmit} personalDetails={personalDetails}/>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>
                            Service Details
                        </StepLabel>
                        <StepContent>
                            <ServiceDetailsForm referralInfo={referralInfo} handleOnCancel={handleCancel} handleOnSubmit={handleServiceDetailsSubmit} handleOnBack={handleServiceDetailsBack} serviceDetails={serviceDetails} />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>
                            Delegate Information
                        </StepLabel>
                        <StepContent>
                            <DelegateInfoForm referralInfo={referralInfo} referralType={serviceDetails() ? serviceDetails().referralType : undefined} handleOnCancel={handleCancel} handleOnSubmit={handleDelegateInfoSubmit} handleOnBack={handleDelegateInfoBack} delegateInfo={delegateInfo() ? delegateInfo() : initialDelegateInfo} />
                        </StepContent>
                    </Step>
                </Stepper>
            }
        </Box>
    );
}