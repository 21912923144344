export const REFERRAL_STATUSES = {
    CREATED: "created",
    ACCEPTED: "accepted",
    REJECTED: "rejected",
    CANCELLED: "cancelled",
    COMPLETED: "completed",
    SENT_FOR_PAYMENT: "sentForPayment"
}
  
export const REFERRAL_STATUS_DISPLAYS = {
    created: "Created",
    accepted: "Accepted",
    rejected: "Rejected",
    cancelled: "Cancelled",
    completed: "Completed",
    sentForPayment: "Sent For Payment"
}

export const REFERRAL_TYPE_COMPENSATION = 'Compensation'

export const earlyInterventionServiceTypeIds = [2, 3, 25, 26, 27, 28]
export const examineeServiceTypeIds = [12, 13, 17, 18, 23, 24, 45, 46, 47, 48, 51, 52, 55, 56]
export const compensationServiceTypeIds = [18, 23, 24, 55]
export const someOptionalFieldsServiceTypeIds = [1, 2, 3, 19, 20, 21, 22, 25, 26, 27, 28]
export const rehabilitationServiceTypeIds = [3, 4, 5, 6, 27, 28, 29, 30, 31, 32, 33, 34]
export const code92ServiceTypeIds = [3, 5, 27, 28, 31, 32]
export const hideCompensationIds = [5, 15, 31, 32]
export const compensationRehabilitationAssessmentServiceTypeIds = [4, 29, 30]
export const originalReferralFieldsServiceTypeIds = [55, 56]

export function isEarlyInterventionType(serviceTypeId) {
    return serviceTypeId && earlyInterventionServiceTypeIds.includes(serviceTypeId);
}

export function isExamineeServiceType(serviceTypeId) {
    return serviceTypeId && examineeServiceTypeIds.includes(serviceTypeId);
}

export function isOptionalFieldServiceType(serviceTypeId) {
    return serviceTypeId && someOptionalFieldsServiceTypeIds.includes(serviceTypeId);
}

export function isCompensationServiceType(serviceTypeId) {
    return serviceTypeId && compensationServiceTypeIds.includes(serviceTypeId);
}

export function isCompensationRehabilitationAssessmentServiceType(serviceTypeId) {
    return serviceTypeId && compensationRehabilitationAssessmentServiceTypeIds.includes(serviceTypeId);
}

export function isCode92ServiceType(serviceTypeId) {
    return serviceTypeId ? code92ServiceTypeIds.includes(serviceTypeId) : false
}

export function isRehabilitationServiceType(serviceTypeId) {
    return serviceTypeId ? rehabilitationServiceTypeIds.includes(serviceTypeId) : false
}

export function hideCompensationQuestions(serviceTypeId) {
    return serviceTypeId ? hideCompensationIds.includes(serviceTypeId) : false
}

export function includeOriginalReferralFields(serviceTypeId) {
    return serviceTypeId ? originalReferralFieldsServiceTypeIds.includes(serviceTypeId) : false
}
